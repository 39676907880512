import React from "react";
import "./Reason.css";
import image1 from "../../assets/photos/DSC_0010(1).jpg";
import image2 from "../../assets/photos/DSC_0035(1).jpg";
import image3 from "../../assets/photos/pexels-julia-larson-6456140.jpg";
import image4 from "../../assets/photos/pexels-andres-ayrton-6551429.jpg";
// import nb from "../../assets/nb.png";
// import adidas from "../../assets/adidas.png";
// import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";

function Reason() {
  return (
    <div className="reason" id="reason">
      <br />
      <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>
      <div className="right-r">
        <span>Take your fitness to the next step</span>

        <div>
          <span className="stroke-text">Why</span>
          <span>Choose Us</span>
        </div>
        <div className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>ONE ON ONE EVALUATION OF FITNESS LEVEL</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>TAILORED PROGRAM TO MEET INDIVIDUAL NEEDS</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>ENSURING PROPER TECHNIQUES</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>PERSONAL TRAINING SESSIONS OR GROUP SESSIONS</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>
              VIRTUAL TRAINING FOR CONVENIENCE, SAFETY, OUT OF TOWN, AND
              TRAVELING CLIENTS
            </span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>DECADES OF EXPERIENCE</span>
          </div>
        </div>
        {/* <span
          style={{
            color: "var(--gray)",
            fontWeight: "normal",
          }}
        >
          OUR PARTNERS
        </span> */}
        {/* <div className="partners">
          <img src={nb} alt="" />
          <img src={adidas} alt="" />
          <img src={nike} alt="" />
        </div> */}
      </div>
    </div>
  );
}

export default Reason;
