import React from "react";
import "./Footer.css";
// import git from "../../assets/github.png";
// import insta from "../../assets/instagram.png";
// import linked from "../../assets/linkedin.png";
// import logo from "../../assets/logo.png";

const Footer = () => {
  const mobile = window.innerWidth <= 768 ? true : false;

  return (
    <div className="footer-container">
      <hr />
      {/* <div className="footer">
        <div className="social-links">
          <img src={git} alt="" />
          <img src={insta} alt="" />
          <img src={linked} alt="" />
        </div>
        <div className="logo-f">
          <img src={logo} alt="" />
        </div>
      </div> */}
      {!mobile ? (
        <div className="footer">
          2023 © Better Body Fitness Inc.
          <a href="mailto:info@mybetterbodyfitness.com">
            info@mybetterbodyfitness.com
          </a>
          Phone: 301.357.0054
        </div>
      ) : (
        <div className="footer">2023 © Better Body Fitness Inc.</div>
      )}
      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
};

export default Footer;
