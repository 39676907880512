import image1 from "../assets/photos/Mr. Wilkerson(1).jpeg";
import image2 from "../assets/photos/Brent Headshot(1).jpg";
// import image3 from "../assets/t-image3.jpg";

export const teamData = [
  {
    image: image1,
    review:
      "Raymond is the CEO and lead Exercise Physiologist of Better Body Fitness Inc. which provides personal training as well as group exercise sessions. Raymond’s personal training methodology uses an individual assessment to determine one's fitness level and then caters the program to meet individual needs. The group exercise sessions are based on a collection of motivating, strengthening and conditioning exercises which are tailored for a group setting while allowing individuals to meet their personal fitness goals. Through his company, Raymond teaches individual how to maximize their fitness successes while improving their overall wellbeing. The Better Body Fitness program developed by Raymond progressively stimulates beginner clients toward better health and elevates more experienced clients to the next level of performance. As a loving father and husband, Raymond makes family a priority. When Raymond is not working to improve his clients’ lives or enjoying his family, he can be found on golf course striving to improve his golf game. Raymond is a certified personal trainer and a member of American college of sports medicine. He holds a BS in Therapeutic Recreation and a MS in Exercise Physiology with over 25 years of experience in the fitness industry. ",
    name: "Raymond A. Wilkerson Jr",
    status: "CEO and Trainer",
  },
  {
    image: image2,
    review:
      "Brent is a certified personal trainer through the National Association of Sports Medicine. Brent's passion for fitness came from loving the aspect of preparing the mind and body through vigorous training programs to make the body perform at the highest level.",
    name: "Brent Wilkerson",
    status: "Trainer",
  },
];
