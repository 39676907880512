import React from "react";
import "./Program.css";
import { programsData } from "../../src/data/programsData.js";
// import RightArrow from "../../src/assets/rightArrow.png";

const Programs = () => {
  return (
    <div className="programs" id="programs">
      <br />
      <div className="programs-header">
        <span className="stroke-text">LOOK BETTER.</span>
        <span>FEEL BETTER.</span>
        <span className="stroke-text">PERFORM BETTER.</span>
      </div>

      <div className="programs-categories">
        {programsData.map((program) => (
          <div className="categories">
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            {/* <div className="join-now">
              <span>Join Now</span>
              <img src={RightArrow} alt="" />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
