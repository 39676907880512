import React from "react";
import "./Testimonials.css";
import image_te from "../../assets/image_te.png";

function Testimonials() {
  return (
    <div className="testimonial" id="testimonial">
      <br />
      <div className="right-te">
        <div>
          <span className="stroke-text">CLIENT </span>
          <span>SUCCESS STORIES</span>
        </div>
        <span>DECHANE</span>
        <div className="details-te">
          <div>
            <span>
              "I have been a client of Better Body Fitness for over 10 years.
              Raymond and his staff are wonderful trainers. I do not like to
              work out. Despite this I realize that I need to. Having a good
              trainer to work with me and to push me in the right direction
              motivates me to achieve my fitness goals. The staff of Better Body
              Fitness does this with expert advice and direction, a pleasant
              disposition, and encouraging words. During my time as a client I
              have had surgeries and injuries that I thought would impact my
              ability to workout. However, Raymond's knowledge as an exercise
              physiologist has enabled him to work with me, within the
              parameters of limitations set by my physician and physical
              therapists, to supplement my rehabilitation and to keep me on
              track. When it comes to fitness Better Body Fitness is able to
              meet you where you are to help get you where you want to be."
            </span>
          </div>
        </div>
        <span>LISA</span>
        <div className="details-te">
          <div>
            <span>
              "Thanks to Raymond, I am now in the best shape of my life. I began
              as an over-worked and stressed mother of two who never worked out
              regularly. Raymond met me where I was, taught me the basics...
              then the intermediate... then the advanced. He nicely but firmly
              led me to where I am today, which is fit, strong and healthy. I
              look great and feel great. I don't know how he did it, but to him
              it was easy. I will be forever grateful…but will never let him
              retire!”
            </span>
          </div>
        </div>
        <span>BILL</span>
        <div className="details-te">
          <div>
            <span>
              "Now that I'm in my 50s I find that fitness is critical to my
              ability to keep enjoying the outdoor activities that I've always
              loved. Injury avoidance is key. Raymond has been great in setting
              up workouts that help me efficiently boost my strength,
              flexibility, and endurance. As importantly, he ensures I use the
              proper form and weight so that I push myself without hurting
              myself."
            </span>
          </div>
        </div>
      </div>
      <div className="left-te">
        <img src={image_te} alt="" />
      </div>
    </div>
  );
}

export default Testimonials;
