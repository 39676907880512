import React from "react";
import Header from "./Header/Header";
import "./Hero.css";
// import hero_image from "../../src/assets/hero_image.png";
import hero_image from "../../src/assets/photos/1dsp-20151218-fit001-1192819663.png";
import hero_image_back from "../../src/assets/hero_image_back.png";
import Heart from "../../src/assets/heart.png";
import Calories from "../../src/assets/calories.png";
import { motion } from "framer-motion";
import NumberCouter from "number-counter";
// import { Link } from "react-scroll";
function Hero() {
  const transition = { type: "spring", duration: 3 };
  // const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="hero" id="home">
      <div className="blur blur-h"></div>
      <div className="left-h">
        <Header />
        {/* 
      <div className="the-best-ad">
        <motion.div 
         initial={{left:mobile?'165px':'238px'}}
         whileInView={{left:'8px'}}
         transition={{...transition,type:'tween'}}
        ></motion.div>
        <span>The best fitness club int he town</span>
      </div> */}

        <div className="hero-text">
          <div>
            <div className="blur blur-1"></div>
            <div className="blur blur-2"></div>

            <span>MY </span>
            <span className="stroke-text">BETTER</span>
          </div>
          <div>
            <span className="stroke-text">BODY </span>

            <span>FITNESS</span>
          </div>
          <div>
            <span>
              {/* Our Mission is to provide a safe and effective fitness program
              based upon fitness evaluation that will cater to each individual
              need that stimulates the mind and body. */}
              Better Body Fitness Inc. has served the metropolitan Washington,
              D.C, area in the fitness industry for over 25 years specializing
              in training, instructing, and management. Our mission is to insure
              a safe and effective fitness model that gratifies and stimulates
              the mind and body of the entire community. Our services are highly
              recommended because of our quality and expertise in the fitness
              industry. Better Body Fitness Inc. has secures and maintained a
              diverse cliental, providing services health care organizations,
              law firms, senior communities, and aspiring athletes. In response
              to a varied client base, the Better Body Fitness program
              customizes sessions to meet the individual.
            </span>
          </div>
        </div>

        <div className="figure">
          <div>
            <span>
              <NumberCouter end={25} start={0} delay="1" preFix="+" />
            </span>
            <span>Years of Experience</span>
          </div>
          <div>
            <span>
              <NumberCouter end={100} start={0} delay="1" preFix="+" />
            </span>
            <span>Healthy Clients</span>
          </div>
          <div>
            <span>Countless</span>
            <span>Lifes Changed</span>
          </div>
        </div>

        {/* <div className="hero-button">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div> */}
      </div>
      <div className="rigth-h">
        {/* <div className="btn">
          <Link to="contactUs" span={true} smooth={true}>
            Contact Us
          </Link>
        </div> */}
        <motion.div
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
          className="heart-rate"
        >
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        <img src={hero_image} alt="" className="hero-image" />
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
          src={hero_image_back}
          alt=""
          className="hero-img-back"
        />

        <motion.div
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
          transition={transition}
          className="calories"
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Hero;
