import React from "react";
import "./join.css";

const Join = () => {
  return (
    <div className="join" id="contactUs">
      <div className="left-j">
        <div>
          <span className="stroke-text">READY TO </span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke-text"> WITH US</span>
        </div>
      </div>
      <div className="right-j"></div>
      <div className="text">
        <span className="text-highlight">
          <b>CONTACT US TO LEARN MORE</b>
        </span>
        <div>
          Email:{" "}
          <a href="mailto:info@mybetterbodyfitness.com">
            info@mybetterbodyfitness.com{" "}
          </a>
        </div>
        <div>Phone: 301.357.0054</div>
      </div>
      {/* <form action="" className="email-container">
        <input type="email" name="user-email" placeholder="Enter your email" />

        <button className="btn btn-j">Join Now</button>
      </form> */}
    </div>
  );
};

export default Join;
